import { Text, Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';

export default function TabContentSection({ data }) {
  return (
    <Tabs>
      <TabList justifyContent="center">
        {data?.tab?.map(({ data }, index) => {
          return <Tab key={`Tab-${index}`}>{data.label}</Tab>;
        })}
      </TabList>

      <TabPanels>
        {data?.tab?.map(({ data }, index) => {
          function createDescriptionMarkup() {
            return { __html: data?.text };
          }
          return (
            <TabPanel key={`TabPanel-${index}`}>
              <Text
                as={'h2'}
                paddingTop="20px"
                paddingBottom="35px"
                fontSize={['28px', '30px', '32px', '36px']}
                lineHeight={['32px', '36px', '38px', '45px']}
                color="brand.900"
                textAlign="center"
              >
                {data.label}
              </Text>
              <Box textAlign={data.alignText ? data.alignText : 'left'}>
                <div className={'tabSection'} dangerouslySetInnerHTML={createDescriptionMarkup()} />
              </Box>
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
}
