import { Text, Box } from '@chakra-ui/react';
import Container from 'components/common/Container';
import ImageProducts from 'components/common/SectionsBlog/ImageProducts';
import Images from 'components/common/SectionsBlog/Images';
import PriceTab from 'components/common/SectionsBlog/PriceTab';
import CarouselProducts from 'components/common/CarouselProducts';
import AccordionSection from 'components/common/BasicPages/AccordionSection';
import Hero from 'components/layouts/Hero';
import FeaturedImages from 'components/common/FeaturedImages';
import Banner from 'components/common/Banner/index';
import ImgGridSection from 'components/common/BasicPages/ImgGridSection';
import TabContentSection from 'components/common/BasicPages/TabContentSection';
import BackgroundVideo from 'components/common/BackgroundVideo';
import SwiperSlider from '../Banner/SwiperSlider';
import VideoBanner from '../Banner/VideoBanner';
import BlackFriday from 'components/BlackFriday';

const RenderSections = ({ sections }) => {
  return (
    <div>
      {sections?.map((item, index) => {
        switch (item?.type) {
          case 'images':
            return item?.data?.images?.map(({ url }, index) => {
              return (
                <Container key={`RenderSections-images-${index}`}>
                  <Images url={url} key={`typeImage-${index}`} alt={`typeImage-${index}`} />
                </Container>
              );
            });
          case 'price_tab': {
            return (
              <PriceTab
                data={item?.data}
                info={item?.data}
                titleLink={item?.data?.title_link}
                key={`priceTab-${index}`}
              />
            );
          }
          case 'content_tab':
            return <TabContentSection data={item?.data} key={`tab-${index}`} />;
          case 'slider':
            return <SwiperSlider item={item} data={item?.data} />;
          case 'video': {
            {
              return <VideoBanner item={item} data={item?.data} />;
            }
          }
          case 'count_down_component':
            return <BlackFriday data={item?.data} />;
          case 'hero_banner':
            return <Hero data={item?.data} position={index} key={`homeSection-${index}`} />;

          case 'image_products':
            return (
              <Container key={`imageProducts-${index}`}>
                <ImageProducts data={item?.data} titleLink={item?.data?.title_link} title={item?.data?.title} />
              </Container>
            );
          case 'products':
            return (
              <CarouselProducts
                data={item?.data?.products}
                title={item?.data?.products?.title}
                titleLink={item?.data?.title_link}
                key={`products-${index}`}
              />
            );
          case 'content_grid':
            return (
              <FeaturedImages
                data={item?.data}
                columns={item?.data?.content?.length}
                key={`content_grid-${index}`}
                position={index}
              />
            );
          case 'banner_text':
            return (
              <Banner
                data={item?.data}
                columns={item?.data?.image?.length}
                key={`banner_text-${index}`}
                position={index}
              />
            );
          case 'accordion':
            return (
              <AccordionSection
                data={item?.data}
                titleLink={item?.data?.title_link}
                key={`AccordionSection-${index}`}
              />
            );
            {
              /* case 'video':
            return item?.full_width ? (
              <BackgroundVideo
                url={item?.data[0]?.url}
                alt={item?.data[0]?.url}
                autoplay={item?.autoplay}
                controls={item?.controls}
                loop={item?.loop}
                is_hero_banner={item?.is_hero_banner}
                title={item?.title}
                subtitle={item?.subtitle}
                image={item?.image}
                cta={item?.cta}
                key={`video-${index}`}
              />
            ) : (
              <Container key={`video-${index}`}>
                <BackgroundVideo
                  url={item?.data[0]?.url}
                  autoplay={item?.autoplay}
                  controls={item?.controls}
                  loop={item?.loop}
                  is_hero_banner={item?.is_hero_banner}
                  title={item?.title}
                  alt={item?.title}
                  subtitle={item?.subtitle}
                  cta={item?.cta}
                  image={item?.image}
                />
              </Container>
            ); */
            }
          case 'images_grid':
            return (
              <Container>
                <Box display="flex" key={`ImgGridSection-${index}`}>
                  <ImgGridSection data={item?.data} title={item?.data.title} />
                </Box>
              </Container>
            );
          case 'text':
            function createTextContent() {
              return { __html: item?.data?.text };
            }
            return (
              <Container key={`textSection-${index}`}>
                <Text as={'div'} p="30px 0 30px 0" dangerouslySetInnerHTML={createTextContent()} />
              </Container>
            );
        }
      })}
    </div>
  );
};

export default RenderSections;
