import { Grid } from '@chakra-ui/react';
import FeaturedImage from '../FeaturedImage';
import Container from 'components/common/Container';

const FeaturedImages = ({ columns, data, position }) => {
  return (
    <Container>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', lg: `repeat(${columns}, 1fr)` }}
        gap={6}
        mb={{ base: '0px', md: '50px' }}
        p="45px 0 80px 0"
      >
        {data?.content?.map(({ data }, index) => {
          return (
            <FeaturedImage
              subtitle={data?.subtitle || data?.title}
              title={data?.title}
              imageUrl={data?.image}
              key={`FeaturedImage-${index}`}
              position={position}
              url={data?.cta}
            />
          );
        })}
      </Grid>
    </Container>
  );
};

export default FeaturedImages;
