import { Box, Image, Grid, GridItem, Button, Link } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import NextLink from 'next/link';

const BackgroundVideo = ({ url, autoplay, controls, loop, hero, is_hero_banner, title, subtitle, cta, image, alt }) => {
  return (
    <Box m="45px 0 80px 0">
      {is_hero_banner === true ? (
        <Box>
          {isMobile ? (
            <Image w={'100%'} h={'auto'} src={image} alt={alt}></Image>
          ) : (
            <Box pos="relative" paddingBottom="56.25%" overflow="hidden" h="0">
              <Box pos="absolute" w="100%" h="100%" left="0" top="0">
                <Box
                  className="video-container"
                  pos={hero ? 'absolute' : 'relative'}
                  w="100%"
                  h="100%"
                  left="0"
                  top="0"
                >
                  <ReactPlayer
                    url={url}
                    alt={url}
                    loop={loop}
                    playing={autoplay}
                    controls={controls}
                    volume={0}
                    width="100%"
                    height="100%"
                    playsinline={true}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box pos="relative" paddingBottom="56.25%" overflow="hidden" h="0">
          <Box pos="absolute" w="100%" h="100%" left="0" top="0">
            <Box
              className="video-container"
              pos={hero ? 'absolute' : 'relative'}
              w="100%"
              h="100%"
              left="0"
              top="0"
            >
              <ReactPlayer
                url={url}
                alt={url}
                loop={loop}
                playing={autoplay}
                controls={controls}
                volume={0}
                width="100%"
                height="100%"
                playsinline={true}
              />
            </Box>
          </Box>
        </Box>
      )}

      {is_hero_banner === true ? (
        <Box py={{ base: '18px', lg: '45px' }} backgroundColor={'brand.900'}>
          <Box as="h2" textStyle="textStyleH" textAlign="center" display={'none'}>
            {title || 'title'}
          </Box>
          <Box
            as="p"
            fontSize={['24px', '24px', '28px', '28px']}
            lineHeight={['28px', '28px', '32px', '32px']}
            color="white"
            mt={{ base: '8px', lg: '12px' }}
            textAlign="center"
          >
            {subtitle}
          </Box>
          <Box display="flex" justifyContent="center" mt={{ base: '18px', lg: '25px' }}>
            <NextLink href={cta?.url || '#'}>
              <Link
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <Button display={'flex'} variant={'outlineInverted'}>
                  {cta?.title || 'Work with us!'}
                </Button>
              </Link>
            </NextLink>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default BackgroundVideo;
