import { Box, Text, Image, Link } from '@chakra-ui/react';
import Tracking from 'utils/tracking';
import NextLink from 'next/link';

const FeaturedImage = ({ subtitle, title, imageUrl, position, url }) => {
  return (
    <Box as={'div'} onClick={() => Tracking.selectPromotion(title, '3 cards Mid', position)}>
      <Box boxSize={('100%', 'auto')}>
        <NextLink href={url?.url || '#'}>
          <Link data-promoid={title}>
            <Image w={'100%'} h={'auto'} src={imageUrl} alt={title || 'image alt'}></Image>
          </Link>
        </NextLink>
      </Box>
      <NextLink href={url?.url || '#'}>
        <Link
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text color="text.primary" fontSize="16px" mb={{ base: '-7px', lg: '8px' }}>
            {subtitle}
          </Text>
        </Link>
      </NextLink>
      <NextLink href={url?.url || '#'}>
        <Link
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text fontSize={['28px', '30px', '32px', '36px']} color="text.primary">
            {title}
          </Text>
        </Link>
      </NextLink>
    </Box>
  );
};

export default FeaturedImage;
