import { Image } from '@chakra-ui/react';

export default function Images({ url, alt }) {
  return (
    <div>
      <>
        <Image src={url} w="auto" p="35px 0" m="auto" alt={alt} />
      </>
    </div>
  );
}
