import { Box } from '@chakra-ui/react';
const Section = ({ titleComponent, children, ...props }) => {
  return (
    <Box as="section" mb={'50px'} {...props}>
      {titleComponent ? titleComponent : null}
      {children}
    </Box>
  );
};

export default Section;
