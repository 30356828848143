import { Box, Image, Link, useBreakpointValue } from '@chakra-ui/react';
import BackgroundVideo from 'components/common/BackgroundVideo';
import NextLink from 'next/link';
import Tracking from 'utils/tracking';

const Hero = ({ data, video, position }) => {
  const imgBanner = useBreakpointValue({
    base: <Image src={data?.mobile_image} w="100%" h="auto" alt={data?.title || 'Hero banner image'} />,
    sm: <Image src={data?.mobile_image} w="100%" h="auto" alt={data?.title || 'Hero banner image'} />,
    md: <Image src={data?.image} w="100%" h="auto" alt={data?.title || 'Hero banner image'} />,
    xl: <Image src={data?.image} w="100%" h="auto" alt={data?.title || 'Hero banner image'} />,
    xxl: <Image src={data?.image} w="100%" h="auto" alt={data?.title || 'Hero banner image'} />,
    xxxl: <Image src={data?.image} w="100%" h="auto" alt={data?.title || 'Hero banner image'} />,
  });

  return (
    <Box
      marginBottom={{ base: '0px', md: '80px', lg: '190px' }}
      mt="20px"
      pos="relative"
      width={'100%'}
      height="auto"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      as="div"
      onClick={() => Tracking.selectPromotion(data.subtitle, 'hero', position)}
    >
      <NextLink href={data?.cta?.url || '#'} passHref prefetch={false}>
        <Link aria-label={data?.title} title={data?.title || 'Hero banner'} data-promoid={data?.title}>
          <>
            {video ? (
              <BackgroundVideo url={data[0]?.url} hero={true} alt={data[0]?.url} />
            ) : (
              <Box className="img-container" width={'100%'}>
                {imgBanner}
              </Box>
            )}
          </>
        </Link>
      </NextLink>
    </Box>
  );
};

export default Hero;
