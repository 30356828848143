import {
  Box,
  Container,
  Text,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  Link,
} from '@chakra-ui/react';
import FadeIn from 'components/transitions/FadeIn';
import NextLink from 'next/link';

const AccordionSection = ({ data, titleLink }) => {
  return (
    <>
      <FadeIn>
        <Box className="FAQ" pos="relative">
          <Box w="100%">
            <Container className="FAQ" maxW="100%">
              <Box textAlign="center" paddingBottom={{ base: '25px', lg: '65px' }}>
                {titleLink ? (
                  <NextLink href={titleLink || '#'} passHref prefetch={false}>
                    <Link>
                      <Text
                        as="h2"
                        textAlign="left"
                        fontSize={['28px', '30px', '32px', '36px']}
                        lineHeight={['32px', '36px', '38px', '45px']}
                        color="brand.900"
                      >
                        {data?.label}
                      </Text>
                    </Link>
                  </NextLink>
                ) : (
                  <Text
                    as="h2"
                    textAlign="left"
                    fontSize={['28px', '30px', '32px', '36px']}
                    lineHeight={['32px', '36px', '38px', '45px']}
                    color="brand.900"
                  >
                    {data?.label}
                  </Text>
                )}

                <Accordion allowMultiple>
                  {data?.accordion?.map(({ data }, index) => {
                    function createDescriptionMarkup() {
                      return { __html: data.text };
                    }
                    return (
                      <AccordionItem key={`accordionItem-${index}`}>
                        <h2>
                          <AccordionButton _expanded={{ color: 'brand.900', boxShadow: 'none' }} paddingLeft="0">
                            <Box flex="1" textAlign="left" fontWeight="bold">
                              {data.label}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel textAlign="left" paddingLeft="0">
                          <div dangerouslySetInnerHTML={createDescriptionMarkup()} />
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </Box>
            </Container>
          </Box>
        </Box>
      </FadeIn>
    </>
  );
};

export default AccordionSection;
