import { Box, Image, Center, Link, Text } from '@chakra-ui/react';
import CarouselSquare from 'components/common/TabListComponent/CarouselSquare';
import NextLink from 'next/link';

export default function ImageProducts({ data, titleLink, title }) {
  return (
    <Box>
      {titleLink ? (
        <NextLink href={titleLink || '#'} passHref prefetch={false}>
          <Link>
            <Text as={'h2'} mb={'20px'} fontSize={['28px', '30px', '32px', '36px']} fontWeight={'400'}>
              {data?.title || 'title'}
            </Text>
          </Link>
        </NextLink>
      ) : (
        <Text as={'h2'} mb={'20px'} fontSize={['28px', '30px', '32px', '36px']} fontWeight={'400'}>
          {data?.title || 'title'}
        </Text>
      )}
      <Box
        display={{ base: 'block', lg: 'flex' }}
        h={{ base: 'auto', lg: '500px' }}
        marginBottom={'50px'}
        flexDirection={data?.direction === 'left' ? 'row' : 'row-reverse'}
      >
        <Box w={{ base: '100%', lg: '50%' }} order="0">
          <Image
            src={data.sideImage}
            objectFit="cover"
            h={{ base: '250px', lg: '500px' }}
            w="100%"
            alt={data?.title || 'image-worn'}
          />
        </Box>
        <Center w={{ base: '100%', lg: '50%' }} h="100%" className={'blog-carousel'} pos="relative">
          <CarouselSquare
            itemPerpageDesktop={1}
            itemPerpageLaptop={3}
            itemPerpageTablet={1}
            itemPerpageMobile={1}
            data={data}
          />
        </Center>
      </Box>
    </Box>
  );
}
