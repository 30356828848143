import { Image, Box, Link, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useRef } from 'react';
import NextLink from 'next/link';

SwiperCore.use([Navigation, Pagination]);

const SwiperSlider = ({ data, item }) => {
  const img = useBreakpointValue({
    base: <Image mt="15px" src={data?.slides[0]?.data?.mobile_image} w="100%" h="auto" alt={'img'} />,
    sm: <Image mt="15px" src={data?.slides[0]?.data?.mobile_image} w="100%" h="auto" alt={'img'} />,
    md: <Image src={data?.slides[0]?.data?.image} w="100%" h="auto" alt={'img'} />,
    xl: <Image src={data?.slides[0]?.data?.image} w="100%" h="auto" alt={'img'} />,
    xxl: <Image src={data?.slides[0]?.data?.image} w="100%" h="auto" alt={'img'} />,
    xxxl: <Image src={data?.slides[0]?.data?.image} w="100%" h="auto" alt={'img'} />,
  });

  return (
    <>
      {data?.slides?.length < 2 ? (
        <NextLink href={data?.slides[0]?.data?.cta?.url || '#'} passHref prefetch={false}>
          <Link>{img}</Link>
        </NextLink>
      ) : (
        <Swiper spaceBetween={50} slidesPerView={1} navigation loop={true} className={'hero-swiper'}>
          {data?.slides?.map((banner) => {
            const imgBanner = useBreakpointValue({
              base: <Image src={banner?.data?.mobile_image} w="100%" h="auto" alt={'img'} />,
              sm: <Image src={banner?.data?.mobile_image} w="100%" h="auto" alt={'img'} />,
              md: <Image src={banner?.data?.image} w="100%" h="auto" alt={'img'} />,
              xl: <Image src={banner?.data?.image} w="100%" h="auto" alt={'img'} />,
              xxl: <Image src={banner?.data?.image} w="100%" h="auto" alt={'img'} />,
              xxxl: <Image src={banner?.data?.image} w="100%" h="auto" alt={'img'} />,
            });
            return (
              <>
                <Box display={{ base: 'none', lg: 'block' }}>
                  <SwiperSlide key={banner?.data?.image}>
                    <NextLink href={banner?.data?.cta?.url || '#'} passHref prefetch={false}>
                      <Link>{imgBanner}</Link>
                    </NextLink>
                  </SwiperSlide>
                </Box>
              </>
            );
          })}
        </Swiper>
      )}
    </>
  );
};

export default SwiperSlider;
