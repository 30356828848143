import { Box, Link, useBreakpointValue, Image } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import NextLink from 'next/link';
import Container from '../Container';
import { useEffect, useState } from 'react';

const VideoBanner = ({ data, item }) => {
  const [classNameDesktop, setClassNameDesktop] = useState('');
  const [classNameMobile, setClassNameMobile] = useState('');
  useEffect(() => {
    if (item?.desktop_video[0]?.type === 'video') {
      setClassNameDesktop('');
    } else {
      setClassNameDesktop('video');
    }
    if (item?.mobile_video[0]?.type === 'video') {
      setClassNameMobile('');
    } else {
      setClassNameMobile('video');
    }
  }, [item]);

  console.log(item, 'item');
  const videoOnly = useBreakpointValue({
    base: (
      <Box
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}

        className={classNameDesktop}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    sm: (
      <Box
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
        className={classNameDesktop}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    md: (
      <Box
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
        className={classNameDesktop}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xl: (
      <Box
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
        className={classNameDesktop}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xxl: (
      <Box
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
        className={classNameDesktop}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xxxl: (
      <Box
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
        className={classNameDesktop}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
  });

  const videoVideo = useBreakpointValue({
    base: (
      <Box
        mt="30px"
        className={classNameMobile}
        // paddingBottom={item?.mobile_video[0]?.format === 'mp4' ? '120%' : '56% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.mobile_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    sm: (
      <Box
        className={classNameMobile}
        mt="30px"
        // paddingBottom={item?.mobile_video[0]?.format === 'mp4' ? '120%' : '56% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.mobile_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    md: (
      <Box
        className={classNameMobile}
        mt="30px"
        // paddingBottom={item?.mobile_video[0]?.format === 'mp4' ? '120%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.mobile_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xl: (
      <Box
        className={classNameDesktop}
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', paddingBottom: '43.25%', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xxl: (
      <Box
        className={classNameDesktop}
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xxxl: (
      <Box
        className={classNameDesktop}
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
  });

  const videoImage = useBreakpointValue({
    base: <Image src={item?.mobile_image} alt="mobile image" />,
    sm: <Image src={item?.mobile_image} alt="mobile image" />,
    md: <Image src={item?.mobile_image} alt="mobile image" />,
    xl: (
      <Box
        className={classNameDesktop}
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xxl: (
      <Box
        className={classNameDesktop}
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xxxl: (
      <Box
        className={classNameDesktop}
        // paddingBottom={item?.desktop_video[0]?.format === 'mp4' ? '43.25%' : '57% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.desktop_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
  });

  const imageVideo = useBreakpointValue({
    base: (
      <Box
        mt="30px"
        className={classNameMobile}
        // paddingBottom={item?.mobile_video[0]?.format === 'mp4' ? '120%' : '56% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.mobile_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    sm: (
      <Box
        mt="30px"
        className={classNameMobile}
        // paddingBottom={item?.mobile_video[0]?.format === 'mp4' ? '120%' : '56% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.mobile_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    md: (
      <Box
        mt="30px"
        className={classNameMobile}
        // paddingBottom={item?.mobile_video[0]?.format === 'mp4' ? '120%' : '56% !important'}
        // style={{ position: 'relative', width: '100%', height: 0 }}
      >
        <ReactPlayer
          url={item?.mobile_video[0]?.url}
          alt="alt"
          loop={true}
          playing={true}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          // style={{ position: 'absolute', top: 0, left: 0 }}
          playsinline={true}
        />
      </Box>
    ),
    xl: <Image src={item?.image} alt="image" />,
    xxl: <Image src={item?.image} alt="image" />,
    xxxl: <Image src={item?.image} alt="image" />,
  });

  const imageImage = useBreakpointValue({
    base: <Image src={item?.mobile_image} alt="mobile image" />,

    sm: <Image src={item?.mobile_image} alt="mobile image" />,

    md: <Image src={item?.mobile_image} alt="mobile image" />,

    xl: <Image src={item?.image} alt="image" />,
    xxl: <Image src={item?.image} alt="image" />,
    xxxl: <Image src={item?.image} alt="image" />,
  });

  const imageOnly = useBreakpointValue({
    base: <Image src={item?.image} alt="mobile image" />,

    sm: <Image src={item?.image} alt="mobile image" />,

    md: <Image src={item?.image} alt="mobile image" />,

    xl: <Image src={item?.image} alt="image" />,
    xxl: <Image src={item?.image} alt="image" />,
    xxxl: <Image src={item?.image} alt="image" />,
  });

  return (
    <Box mt="20px" width={'100%'}>
      {item?.desktop_video?.length > 0 && item?.mobile_video?.length < 1 && item?.mobile_image === null ? (
        <NextLink href={item?.cta?.url || '#'} passHref prefetch={false}>
          <Link aria-label={item?.cta?.url}>{videoOnly}</Link>
        </NextLink>
      ) : item?.desktop_video?.length < 1 &&
        item?.mobile_video?.length < 1 &&
        item?.mobile_image === null &&
        item?.image ? (
        <NextLink href={item?.cta?.url || '#'} passHref prefetch={false}>
          <Link aria-label={item?.cta?.url}>{imageOnly}</Link>
        </NextLink>
      ) : item?.desktop_video?.length < 1 && item?.image === null ? (
        ''
      ) : item?.desktop_video?.length > 0 && item?.mobile_video?.length > 0 ? (
        <NextLink href={item?.cta?.url || '#'} passHref prefetch={false}>
          <Link aria-label={item?.cta?.url}>{videoVideo}</Link>
        </NextLink>
      ) : item?.desktop_video?.length < 1 && item?.mobile_video?.length > 0 ? (
        <NextLink href={item?.cta?.url || '#'} passHref prefetch={false}>
          <Link aria-label={item?.cta?.url}>{imageVideo}</Link>
        </NextLink>
      ) : item?.desktop_video?.length < 1 && item?.mobile_video?.length < 1 ? (
        <NextLink href={item?.cta?.url || '#'} passHref prefetch={false}>
          <Link aria-label={item?.cta?.url}>{imageImage}</Link>
        </NextLink>
      ) : item?.desktop_video?.length > 0 && item?.mobile_video?.length < 1 ? (
        <NextLink href={item?.cta?.url || '#'} passHref prefetch={false}>
          <Link aria-label={item?.cta?.url}>{videoImage}</Link>
        </NextLink>
      ) : (
        ''
      )}
    </Box>
  );
};

export default VideoBanner;
