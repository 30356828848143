import { Box, Image } from '@chakra-ui/react';

export default function ImgGridSection({ data, title }) {
  return data?.images?.map(({ url }, index) => {
    return (
      <Box
        className="basic-images"
        w={{ base: '100%', md: '50%' }}
        py="20px"
        _odd={{ pr: { base: '0', md: '10px' } }}
        _even={{ pl: { base: '0', md: '10px' } }}
        key={`img-${index}`}
      >
        <Image w="100%" h="100%" objectFit="cover" src={url} alt={url} />
      </Box>
    );
  });
}
