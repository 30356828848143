import { Text, Link } from '@chakra-ui/react';
import { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import FeaturedProduct from 'components/common/FeaturedProduct';
import Container from 'components/common/Container';
import NextLink from 'next/link';

const CarouselProducts = ({ data, title, titleLink }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    largeLaptop: {
      breakpoint: { max: 1200, min: 992 },
      items: 4,
      slidesToSlide: 5, // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 992, min: 768 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 320 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  /*  useEffect(() => {
    Tracking.viewItemListRelativeItems(data);
  }, []);*/

  useEffect(() => {
    const carouselItems = Array.from(document.getElementsByClassName('react-multi-carousel-item'));
    carouselItems.forEach((element) => element.setAttribute('tab-index', -1));
  }, []);

  return (
    <Container mb="50px">
      {titleLink ? (
        <NextLink href={titleLink || '#'} passHref prefetch={false}>
          <Link>
            <Text as={'h1'} mb={'20px'} display="none" fontSize={['28px', '30px', '32px', '36px']} fontWeight={'400'}>
              {title || 'title'}
            </Text>
          </Link>
        </NextLink>
      ) : (
        <Text as={'h1'} mb={'20px'} display="none" fontSize={['28px', '30px', '32px', '36px']} fontWeight={'400'}>
          {title || 'title'}
        </Text>
      )}
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={false}
        autoPlay={false}
        keyBoardControl={true}
        customTransition="all .4s linear"
        transitionDuration={500}
        containerClass="carousel-container"
        deviceType={'desktop'}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {data?.map(
          ({
            image,
            category,
            path,
            price_formatted,
            title,
            price,
            product_id,
            mastersku,
            discount_percentage,
            list_price_formatted,
            tags,
            default_variation,
          }) => (
            <FeaturedProduct
              key={product_id}
              title={title}
              price={price_formatted}
              normalPrice={price}
              url={path}
              image={image}
              mastersku={mastersku}
              category={category}
              list_price_formatted={list_price_formatted}
              discount_percentage={discount_percentage}
              tags={tags}
              sku={default_variation?.sku}
            />
          )
        )}
      </Carousel>
    </Container>
  );
};

export default CarouselProducts;
