import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import CarouselSquare from './CarouselSquare';
import TabItem from 'components/common/TabItem';
import Container from 'components/common/Container';
import { useState } from 'react';

const TabListComponent = ({ data, info }) => {
  const [isRendered, setIsRendered] = useState(false);
  return (
    <>
      <Container>
        <Tabs isLazy={true} onChange={() => setIsRendered(true)}>
          <TabList display="flex" justifyContent="center">
            {data?.sections.map(({ data }, index) => (
              <TabItem label={data.label} key={`TabItem-${index}`} />
            ))}
          </TabList>

          <TabPanels>
            {data?.sections.map(({ data }, index) => (
              <TabPanel key={`TabPanel-${index}`}>
                <CarouselSquare isRendered={isRendered} data={data} info={info} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Container>
    </>
  );
};

export default TabListComponent;
