import { useRouter } from 'next/router';
import { getRealLocale } from 'utils/helpers';
import useUser from 'hooks/useUser';
import Tracking from 'utils/tracking';

export default function useTracking() {
  const router = useRouter();
  const locale = getRealLocale(router.locale);
  const { userData } = useUser();

  const dispatchSectionEvents = (sections) => {
    if (!sections) return;

    const impressions = sections
      .filter(({ type, data }) => {
        if (type === 'hero_banner' || type === 'banner_text' || type === 'content_grid') {
          if (data?.cta?.url !== '/blog') {
            return data;
          }
        }
      })
      .map((item) => {
        if (item.type === 'content_grid') {
          return {
            data: item.data.content.map((nested) => {
              return { data: nested.data };
            }),
            creative: 'content_grid',
          };
        }

        return { data: item.data, creative: item.type };
      });

    Tracking.viewPromotion(impressions);

    let listItems = [];

    sections.map((i) => {
      switch (i.type) {
        case 'products':
        case 'image_products':
          // Tracking.viewItemListRelativeItems(i?.data?.products, 'Προτεινόμενα');
          listItems = [
            ...listItems,
            ...i?.data.products.map((item, index) => {
              return { ...item, list_name: 'Προτεινόμενα', index: index + 1 };
            }),
          ];

          break;
        case 'price_tab':
          listItems = [
            ...listItems,
            ...i?.data.sections[0].data?.products.map((item, index) => {
              return {
                ...item,
                list_name: `${i?.data?.label || ''}${i?.data?.sections?.[0]?.data?.label || ''}`,
                index: index + 1,
              };
            }),
          ];
          break;
        default:
          break;
      }

      return i;
    });

    const getbatchedLists = (data) => {
      //split stores in arrays of maximum 20 items
      const batchedArray = data?.reduce((accumulator, currentValue, currentIndex) => {
        const arrayIndex = Math.floor(currentIndex / 12);
        if (accumulator[arrayIndex]) {
          accumulator[arrayIndex].push(currentValue);
        } else {
          accumulator[arrayIndex] = [currentValue];
        }
        return accumulator;
      }, []);

      batchedArray?.map((data) => {
        Tracking.viewItemListRelativeItems(data, false, false, false, true);
      });

      return batchedArray;
    };
    getbatchedLists(listItems);
  };

  const pageView = (type, pageProps) => {
    if (!pageProps) return;
    Tracking.pageView(
      type,
      locale,
      `/${router.locale}${router.asPath}`,
      null,
      null,
      userData.id || '',
      document.title || pageProps?.pageData?.title || type
    );
  };

  const dispatchAllEventsByPageType = (type, pageProps) => {
    switch (type) {
      case 'Homepage':
        pageView(type, pageProps);
        dispatchSectionEvents(pageProps?.pageData?.sections);

        break;

      case 'Product':
        pageView(type, pageProps);

        dispatchSectionEvents(pageProps?.pageData?.sections);
        Tracking.viewItem(pageProps?.pageData);
        Tracking.viewItemListRelativeItems(pageProps.pageData?.relativeItems, false, true);
        break;
      case 'Other':
        pageView(pageProps?.pageData?.events?.custom_page_type || 'Blog', pageProps);
        break;
      case 'Article':
        pageView('Blog', pageProps);
        break;
      default:
        break;
    }
  };

  return {
    pageView,
    dispatchAllEventsByPageType,
  };
}
