import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselSquareItem from './CarouselSquareItem';
import FadeIn from 'components/transitions/FadeIn';
import Tracking from 'utils/tracking';
import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useState, useRef } from 'react';

const CarouselSquare = ({
  itemPerpageDesktop,
  itemPerpageLaptop,
  itemPerpageTablet,
  itemPerpageMobile,
  data,
  extraResponsive,
  isRendered,
  info,
}) => {
  const carouselRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: itemPerpageDesktop ? itemPerpageDesktop : 4,
      slidesToSlide: itemPerpageDesktop ? itemPerpageDesktop : 4, // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 992, min: 768 },
      items: itemPerpageLaptop ? itemPerpageLaptop : 3,
      slidesToSlide: itemPerpageLaptop ? itemPerpageLaptop : 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: itemPerpageTablet ? itemPerpageTablet : 2,
      slidesToSlide: itemPerpageTablet ? itemPerpageTablet : 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 320 },
      items: itemPerpageMobile ? itemPerpageMobile : 1,
      slidesToSlide: itemPerpageMobile ? itemPerpageMobile : 1, // optional, default to 1.
    },
  };

  const handleSlideChange = (_, { currentSlide }) => {
    setActiveIndex(currentSlide);
  };

  useEffect(() => {
    if (isRendered) {
      const item_list_name = `${info?.label}${data?.label}`;
      Tracking.viewItemListRelativeItems(data?.products, false, false, true, false, item_list_name);
    }
  }, [data.label]);

  return (
    <FadeIn>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={extraResponsive ? extraResponsive : responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={false}
        keyBoardControl={true}
        slidesToSlide={1}
        customTransition="all .4s linear"
        transitionDuration={500}
        containerClass="carousel-container"
        deviceType={'desktop'}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        ref={carouselRef}
        afterChange={handleSlideChange}
      >
        {data?.products?.map(
          (
            {
              mastersku,
              title,
              path,
              tags,
              category,
              image,
              price_formatted,
              discount_percentage,
              price,
              list_price_formatted,
              list_price,
              default_variation,
            },
            index
          ) => {
            const isVisible = index === activeIndex;
            return (
              <CarouselSquareItem
                mastersku={mastersku}
                isVisible={isVisible}
                index={index}
                title={title}
                path={path}
                category={category}
                info={info}
                image={image}
                price={price_formatted}
                normalPrice={price}
                list_price={list_price}
                list_price_formatted={list_price_formatted}
                discount_percentage={discount_percentage}
                tags={tags}
                stock_level={default_variation?.stock_level}
                sku={default_variation?.sku}
                key={`CarouselSquareItem-${index}`}
                item_list_name={`${info?.label}${data?.label}`}
              />
            );
          }
        )}
      </Carousel>
    </FadeIn>
  );
};

export default CarouselSquare;
