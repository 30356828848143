import { Box, Image, Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import Tracking from 'utils/tracking';
import { generateUniqueId } from 'utils/helpers';

const CarouselSquareItem = ({
  title,
  path,
  image,
  tags,
  price,
  discount_percentage,
  list_price_formatted,
  mastersku,
  category,
  normalPrice,
  list_price,
  info,
  item_list_name,
  stock_level,
  sku,
  key,
  isVisible,
  index,
}) => {
  const imageId = generateUniqueId();
  const linkId = generateUniqueId();
  return (
    <>
      <Box
        className="square-ratio"
        as="div"
        onClick={() =>
          Tracking.selectItem(
            title,
            mastersku,
            normalPrice,
            path,
            category,
            stock_level,
            list_price,
            item_list_name,
            sku
          )
        }
      >
        {tags?.map(({ tid }, index) => {
          return tid === 441 ? (
            <Image
              key={`CarouselSquareItemImage-${index}`}
              alt={`CarouselSquareItemImage-${index}`}
              width={{ base: '35px', md: '50px' }}
              height={{ base: '35px', md: '50px' }}
              src={'/assets/express.svg'}
              pos="absolute"
              id={imageId}
              bottom="0"
              right="0"
              backgroundColor="white"
              borderRadius="50%"
              padding="3px"
            />
          ) : null;
        })}
        <Box as={'div'} position="absolute" w="100%" h="100%" padding="2px">
          <NextLink href={path || '#'} passHref prefetch={false}>
            <Link id={linkId} w="100%" h="100%" data-productid={mastersku}>
              <Image id={imageId} src={image} alt={title} />
            </Link>
          </NextLink>
        </Box>
      </Box>
      <Box as={'div'} textAlign={'left'} marginTop="15px">
        <Link id={`tabs-1--tab-0 ${mastersku}3`} _hover={{ textDecoration: 'none', color: 'brand.900' }}>
          <Box
            as={'p'}
            fontWeight="normal"
            fontSize={{ base: '16px', lg: '20px' }}
            lineHeight={{ base: '20px', lg: '25px' }}
            p="8px 8px 8px 0"
            isTruncated
            textAlign="center"
          >
            {title}
          </Box>
        </Link>

        <Box display="flex" justifyContent="center">
          {list_price_formatted ? (
            <Box as={'p'} fontSize={{ base: '22px', lg: '20px' }} mb={'20px'} mr="12px" color={'lightGrey'}>
              <del>{list_price_formatted}</del>
            </Box>
          ) : (
            ''
          )}
          <Box
            as={'p'}
            fontWeight={700}
            fontSize={{ base: '22px', lg: '20px' }}
            mb={'20px'}
            color={'text.primary'}
            mr="12px"
          >
            {price}
          </Box>
          {discount_percentage ? (
            <Box as={'p'} fontWeight={700} fontSize={{ base: '22px', lg: '20px' }} mb={'20px'} color={'brand.900'}>
              {discount_percentage}
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </>
  );
};

export default CarouselSquareItem;
